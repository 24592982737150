import React, {useState} from "react";

import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import Translate from "@accrosoft-ltd/accropress-websites-components/dist/esm/translateWidget";

const TopNavComponent = (props) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [searchExpanded, setSearchExpanded] = useState(false);
  const [translateExpanded, setTranslateExpanded] = useState(false);

  const translateOnClick = function (e) {
    if (translateExpanded) {
      setTranslateExpanded(false);
    } else {
      e.preventDefault();
      setTranslateExpanded(true);
    }
  };

  const searchOnClick = function (e) {
    if (searchExpanded) {
      setSearchExpanded(false);
    } else {
      e.preventDefault();
      setSearchExpanded(true);
    }
  };

  return (
    <div className="topNav">
      <ul className="topLinks">
        <li>
          <a
            href="https://www.weduc.com"
            target="_blank"
            rel="external noopener noreferer"
            className="parentLink"
          >
            <span>Weduc</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.parentpay.com/schools/our-product/"
            target="_blank"
            rel="external noopener noreferer"
            className="parentLink"
          >
            <span>Parentpay</span>
          </a>
        </li>
      </ul>

      <ul className="nav-extras">
        <li
          className={translateExpanded ? "translate-top show" : "translate-top"}
        >
          <button type="submit" onClick={(e) => translateOnClick(e)}>
            {translateExpanded ? "" : ""}
            <span className="social fal fa-globe"></span>
          </button>
          <Translate />
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UC0i6Cw9wfku05C0NlM5SiAg" target="_blank">
            <span className="fas fa-play social"></span>
          </a>
        </li>

        <li>
          <a href="https://twitter.com/WalsgraveSchool" target="_blank">
            <span className="fab fa-twitter social"></span>
          </a>
        </li>

        <li className={searchExpanded ? "search-top show" : "search-top"}>
          <form action="/search/" method="get">
            <button type="submit" onClick={(e) => searchOnClick(e)}>
              {searchExpanded ? "" : ""}
              <span className="far fa-search social"></span>
            </button>
            <input name="term" id="Search" placeholder="Search"></input>
          </form>
        </li>

        <li className="trust-group">
          <div className="trust-group-open">
            <button className="trust-group" onClick={onOpenModal}>
              Inspire Education Trust
            </button>
            <Modal open={open} onClose={onCloseModal} center>
              <div className="school-group">
                <div className="row">
                  <div className="col-12">
                    <div className="header">Inspire Education Trust</div>
                    <div className="schools">
                      <div className="row">
                        <div className="col-12 col-4-l">
                          <div className="school">
                            <a
                              href="https://www.ietrust-bluecoat.weduc.website"
                              target="_blank"
                            >
                              <div className="school-image">
                                <img
                                  src="https://cdn2.accropress.com/672a8fa7-1ed8-4c93-a467-c53f6196e9c5/media/global/88fa6e6a-054c-46fb-9a4c-363e293b25ce_full_blue-coat-academy-small.jpg"
                                  alt="blue coat school"
                                />
                              </div>
                            </a>
                            <div className="school-name">
                              <span>
                                <a
                                  href="https://www.ietrust-bluecoat.weduc.website"
                                  target="_blank"
                                >
                                  Blue Coat
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-4-l">
                          <div className="school">
                            <a
                              href="https://www.ietrust-whittle.weduc.website"
                              target="_blank"
                            >
                              <div className="school-image">
                                <img
                                  src="https://cdn2.accropress.com/672a8fa7-1ed8-4c93-a467-c53f6196e9c5/media/global/93dace27-10ce-4369-ad95-42ced90210b9_full_whittle-academy-small.jpg"
                                  alt="whittle school"
                                />
                              </div>
                            </a>
                            <div className="school-name">
                              <span>
                                <a
                                  href="https://www.ietrust-whittle.weduc.website"
                                  target="_blank"
                                >
                                  Whittle Academy
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-4-l">
                          <div className="school">
                            <a
                              href="https://www.ietrust-clifford.weduc.website"
                              target="_blank"
                            >
                              <div className="school-image">
                                <img
                                  src="https://cdn2.accropress.com/672a8fa7-1ed8-4c93-a467-c53f6196e9c5/media/global/6147b7f7-f663-4085-8ba3-2d1b3899db7d_full_clifford-academy-small.jpg"
                                  alt="clifford bridge academy"
                                />
                              </div>
                            </a>
                            <div className="school-name">
                              <span>
                                <a
                                  href="https://www.ietrust-clifford.weduc.website"
                                  target="_blank"
                                >
                                  Clifford Bridge Academy
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-4-l">
                          <div className="school">
                            <a
                              href="https://www.ietrust-stockingford.weduc.website"
                              target="_blank"
                            >
                              <div className="school-image">
                                <img
                                  src="https://cdn2.accropress.com/672a8fa7-1ed8-4c93-a467-c53f6196e9c5/media/global/43883200-6c68-49c4-b512-a16a1b006488_full_stockingford-academy-small.jpg"
                                  alt="stockingford academy"
                                />
                              </div>
                            </a>
                            <div className="school-name">
                              <span>
                                <a
                                  href="https://www.ietrust-stockingford.weduc.website"
                                  target="_blank"
                                >
                                  Stockingford Academy
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-4-l">
                          <div className="school">
                            <a
                              href="https://www.ietrust-hearsall.weduc.website"
                              target="_blank"
                            >
                              <div className="school-image">
                                <img
                                  src="https://cdn2.accropress.com/672a8fa7-1ed8-4c93-a467-c53f6196e9c5/media/global/be04109b-2db1-4dc7-88fd-1ea45a9f588a_full_hearsall-academy-small.jpg"
                                  alt="hearsall community academy"
                                />
                              </div>
                            </a>
                            <div className="school-name">
                              <span>
                                <a
                                  href="https://www.ietrust-hearsall.weduc.website"
                                  target="_blank"
                                >
                                  Hearsall Community Academy
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-4-l">
                          <div className="school">
                            <a
                              href="https://www.ietrust-walsgrave.weduc.website"
                              target="_blank"
                            >
                              <div className="school-image">
                                <img
                                  src="https://cdn2.accropress.com/672a8fa7-1ed8-4c93-a467-c53f6196e9c5/media/global/6024a2e2-0827-42e1-9ffa-6c8037381159_full_walsgrave-academy-small.jpg"
                                  alt="walsgrave church of england academy"
                                />
                              </div>
                            </a>
                            <div className="school-name">
                              <span>
                                <a
                                  href="https://www.ietrust-hearsall.weduc.website"
                                  target="_blank"
                                >
                                  Walsgrave Church of England Academy
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TopNavComponent;
